const AudienceEdit = () => import('@/modules/audiences/views/AudienceEdit.vue')
const AudienceIndex = () => import('@/modules/audiences/views/AudienceIndex.vue')

export default [
  {
    path: '/audiences',
    name: 'audiences.index',
    component: AudienceIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/audiences/create',
    name: 'audiences.create',
    component: AudienceEdit,
    meta: { requiresAuthentication: true }
  }
]
