const WatcherEdit = () => import('@/modules/watchers/views/WatcherEdit.vue')
const WatcherShow = () => import('@/modules/watchers/views/WatcherShow.vue')

export default [
  {
    path: '/watchers/create',
    name: 'watchers.create',
    component: WatcherEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/watchers/:id',
    name: 'watchers.show',
    component: WatcherShow,
    meta: { requiresAuthentication: true }
  }
]
